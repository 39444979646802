import React,{ useContext,useEffect,useState } from "react"

import { AppContext } from "./App"
import { Link } from "react-router-dom"
export default function Offcanvas (){
  
  const [recommendation,setRecommendation] = useState()
  const {settings} = useContext(AppContext)
  const version = settings.filter((item)=> item.title === 'recommendation')[0]
  useEffect(()=>{
    if(version){
      setRecommendation(version.active === 1 ? true : false)
    }
  }, [version])
  
  return (
        <>
        
        <div className="offcanvas offcanvas-end" tabindex="-1" id="demo" aria-labelledby="offcanvasExampleLabel">
  <div className="offcanvas-header justify-end flex">
    <button type="button" className="text-main" data-bs-dismiss="offcanvas" aria-label="Close">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
  <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
</svg>


    </button>
  </div>
  <div className="offcanvas-body flex flex-col justify-between">
    <ul className='p-2  '>

      <li className='hidden mb-3 rounded text-yellow font-semibold items-center p-2'>
        {/* <form className='flex gap-3 align-items-center'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
</svg>





          <div className="dropdown w-full flex justify-between">
      <span>Langue</span>
      <button className="" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown">
      
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
  <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
</svg>

      </button>
      
      <ul className="dropdown-menu border-none shadow" aria-labelledby="dropdownMenuButton">
        <li><button className="dropdown-item" >English</button></li>
        <li><button className="dropdown-item" >Francais</button></li>
        <li><button className="dropdown-item" >العربية</button></li>
      </ul>
    </div>
        </form> */}
      </li>
      <li className='mb-3 rounded text-yellow font-semibold items-center p-2'>
        <a href="tel:0520502676" className='flex gap-3 align-items-center'>

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
              <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clip-rule="evenodd" />
          </svg>

        Contacter nous
        </a>
      </li>
      <li className='mb-3 rounded text-yellow font-semibold items-center p-2'>
        <a href="https://www.google.com/maps?s=web&lqi=ChNyZXN0YXVyYW50IGxhIHJ1Y2hlSI-hzf-nuICACFolEAAQARACGAAYARgCIhNyZXN0YXVyYW50IGxhIHJ1Y2hlMgJmcpIBCnJlc3RhdXJhbnSqATsQATIeEAEiGvKCynXwbbt4Tm25zsZIZ9Acn7axImhquWYhMhcQAiITcmVzdGF1cmFudCBsYSBydWNoZQ&phdesc=J1McW576jNw&vet=12ahUKEwiJs_zow6aFAxXnU6QEHZ-EAyYQ1YkKegQIFhAB..i&cs=0&um=1&ie=UTF-8&fb=1&gl=ma&sa=X&geocode=KR-hFVJPPaYNMbBzIRKi-zxG&daddr=Bd+salama+moussa+layaly,+Berrchid+26100" className='flex gap-3 align-items-center'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
              <path fill-rule="evenodd" d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clip-rule="evenodd" />
          </svg>


          Trouver nous  
        </a>
      </li>
{recommendation ? <Link to='/restaurant/recommendation'>

  <li className='mb-3 rounded text-yellow font-semibold items-center p-2'>
        <a href="https://www.google.com/maps?s=web&lqi=ChNyZXN0YXVyYW50IGxhIHJ1Y2hlSI-hzf-nuICACFolEAAQARACGAAYARgCIhNyZXN0YXVyYW50IGxhIHJ1Y2hlMgJmcpIBCnJlc3RhdXJhbnSqATsQATIeEAEiGvKCynXwbbt4Tm25zsZIZ9Acn7axImhquWYhMhcQAiITcmVzdGF1cmFudCBsYSBydWNoZQ&phdesc=J1McW576jNw&vet=12ahUKEwiJs_zow6aFAxXnU6QEHZ-EAyYQ1YkKegQIFhAB..i&cs=0&um=1&ie=UTF-8&fb=1&gl=ma&sa=X&geocode=KR-hFVJPPaYNMbBzIRKi-zxG&daddr=Bd+salama+moussa+layaly,+Berrchid+26100" className='flex gap-3 align-items-center'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
              <path d="M7.493 18.5c-.425 0-.82-.236-.975-.632A7.48 7.48 0 0 1 6 15.125c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75A.75.75 0 0 1 15 2a2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23h-.777ZM2.331 10.727a11.969 11.969 0 0 0-.831 4.398 12 12 0 0 0 .52 3.507C2.28 19.482 3.105 20 3.994 20H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 0 1-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227Z" />
            </svg>




         Recommendation
        </a>
      </li>
      </Link> : null}
      
    </ul>
    <ul className="p-2">
    <li className='mb-3 text-lg border rounded-3xl text-green-500 font-semibold items-center hover:bg-green-700 hover:text-white p-2'>
        <a href="https://glovoapp.com/ma/fr/berrechid/la-ruche-du-jardin-brh/" className='flex gap-3 align-items-center'>
          <img src="https://cdn.freelogovectors.net/wp-content/uploads/2023/05/glovo_logo-freelogovectors.net_-1.png" alt="la ruche du jardin"  className="w-8 h-8 object-cover object-center bg-white p-1 rounded-3xl"/>


            Passer une command sur glovo
        </a>
      </li>
    </ul>
  </div>
</div>
        </>
    )
}