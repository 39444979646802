import React from 'react';
import { Link } from 'react-router-dom';
import Offcanvas from './headerMenu';
// import AppContext from './App'
import { ClassLoader } from './loader';
const ClassePage = ({data,versions}) => {
    // const {theme,language,changeTheme,changeLanguage} = useContext(AppContext); 
    const modal = document.querySelector('.modal-open');
    if(modal) {
      modal.style.overflow = "scroll";
      modal.style.padding = "0";
     

    }
   

    return (
      <>
        <Header />
        <div className='mt-5 text-center max-w-5xl px-10 mx-auto '>
            <h2 className='h2 animated'>
              Choisissez votre catégorie préférée
            </h2>
        </div>
        <ListClasses data={data}>
          
        </ListClasses>
        
        <Footer />
      </>
    )
}
export default ClassePage; 

const ListClasses = ({data}) =>{
  return (<>
  
  <div className="py-5">
          <div className="mx-auto grid max-w-5xl px-10 ">
            
            <ul id="classe" className="grid items-center gap-x-8 gap-y-12 sm:grid-cols-3 sm:gap-y-16 xl:col-span-2 ">
              
              {data.length>0 ?data.map((item) => (
                <>
                <Link to={`/restaurant/menu/${item.title}`}>
                <li key={item} className="max-w-xs m-auto w-3/4 animated" >
                  <div className="items-center text-center py-10 gap-y-6 shadow-xl bg-white ma">
                    <img className="object-cover h-36 w-36 m-auto rounded-full bg-gray-200" src={`${item.icon}`} alt="" />
                    <div>
                      <h3 className="capitalize mt-4 px-4 text-2xl text-green-800 font-medium leading-7 tracking-tight text-gray-900">{item.title}</h3>
                      <p className="text-sm leading-7 text-gray-500 ">{item.description}</p>
                    </div>
                  </div>
                </li>
                </Link>
                </>
                
              )):
              <ClassLoader />
            }
            </ul>
            
          </div>
        </div>
  </>)
}

const Header = ()=>{
  return (
    <>
    <div className='bg-main bg-fixed' id='clipped' style={{ background:"url('https://laruchedujardin.ma/src/background1.jpg')",
                    backgroundRepeat :"no-repeat",
                    backgroundSize:"cover",
                    backgroundPosition:"top",
                    backgroundAttachment:"fixed",
                    transition:" 0.3s"
                    
                  }}>
          <HeaderBar />
          <HeaderSection/>
    </div>
    </>
  )
}
const HeaderBar = ()=>{
  return (<>
  <header className="sectionHeader ">
            <div className="w">
              <img className="w-full " src="https://laruchedujardin.ma/src/logo.png" alt="la ruche du jardin" />
            </div>
            <div className="w links">
              <button type="button" data-bs-toggle="offcanvas" data-bs-target="#demo" >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-main">
                <path fill-rule="evenodd" d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm8.25 5.25a.75.75 0 0 1 .75-.75h8.25a.75.75 0 0 1 0 1.5H12a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd" />
              </svg>

                
              </button>

            </div>
  </header>






<Offcanvas/>
  </>)
}
const HeaderSection = ()=>{
  return (<>
  <div style={{height:"100vh"}} className="text-center py-10 mb-10 px-5 " >
                <div>
                <h2 className="text-white text-7xl font-semibold tracking-tight sm:text-8xl neon ">Menu</h2>
                <p className="text-white mt-6 text-lg leading-10 clear-p handwriting" >
                “Good food is the foundation of genuine happiness”
                </p>
                </div>
                <div className='h-50 flex justify-center  align-items-end item-center'>
                  <button  className='' onClick={()=>{window.scroll(0,window.screen.availHeight - 111)}}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-12 h-12 text-white animate-bounce">
    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-.53 14.03a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V8.25a.75.75 0 0 0-1.5 0v5.69l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3Z" clip-rule="evenodd" />
  </svg>
  
  
                  </button>
                </div>
          </div>
  </>)
}
const Footer = ()=>{
  return (
    <>
            <footer className='p-4 text-gray-700 mt-5 '>
              <div class="row align-items-center ">
                  <div class="col text-center hidden">
                    <ul class="" >
                      <li><button  >English</button></li>
                      <li><button  >Francais</button></li>
                      <li><button  >العربية</button></li>
                    </ul>
                  </div>
                  <div class="col justify-center flex gap-3">
                    <a class="" href="https://www.facebook.com/profile.php?id=100087318735252&mibextid=ZbWKwL"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook  w-6 h-6" viewBox="0 0 16 16">
                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                      </svg>
                      </a>
                    <a class="" href="https://www.instagram.com/restaurant_la_ruche/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram  w-6 h-6" viewBox="0 0 16 16">
                          <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                        </svg>
                      </a>
                    <a class="" href="https://wa.me/2120717911970">
                      <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-whatsapp w-6 h-6" viewBox="0 0 16 16">
                          <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
                        </svg></a>
                    <a class="" href="tel:0520502676">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 ">
                        <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clip-rule="evenodd" />
                      </svg>

                    </a>
                    
                    
                    
                  </div>
              </div>
              <p className='text-center p-2 pt-4'> Restaurant la ruche du jardin </p>
            </footer>
    </>
  )
}