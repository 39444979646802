import React, { useState , useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Offcanvas from './headerMenu';
import { MenuLoader } from './loader';

const Menu =({data})=>{
  
    const [handleToChange,setHandleToChange]=useState({});
    const [menuData,setData] = useState([])
    const [photos,setPhotos] = useState([])
    const { classe } = useParams();

    const modal = document.querySelector('.modal-open');
    if(modal) {
      modal.style.overflow = "";
      modal.style.padding = "0";
    }

    useEffect(() => {

      const fetchData = async () => {
        try {
          const responseMenu = await fetch('https://laruchedujardin.ma/api/plats.php');
          const menuData = await responseMenu.json();
          setData(menuData)

          const responseGallery = await fetch('https://laruchedujardin.ma/api/gallery.php');
          const photos = await responseGallery.json();
          setPhotos(photos)
          
        }
         catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData();
    }, []);
    const dishes = menuData.filter((item)=> item.class === classe);





    console.log(photos)
    const overFlow =(e)=>{

      setHandleToChange(e);
    }

    const uniqueCategories = [...new Set(dishes.map(item=> item.category))]
    return (
        <>
        <>
        <MenuHeader titre={classe}/>
        </>
         
          <div className='m-auto ' id="menuLayout">
          {
          menuData.length === 0
          ?
          <MenuLoader/>
          :
          ''
          } 
            <CategoryNavbar uniqueCategories={uniqueCategories} />
            <div className="bg-white shadow-lg mb-10 " style={{borderRadius:"2em"}}>
    
              { uniqueCategories.map((category)=>{
                const list = dishes.filter((item)=> item.category === category);
                return <main className='py-4'>
                  {classe !== category ? 

                    <fieldset >
                      <legend id={category} className="font-semibold text-base capitalize text-gray-400 bg-white">
                        {category}
                      </legend>
                      
                    </fieldset> : ""
                  }
        
                  <ul className="divide-y divide-gray-100">
        
                  { list.map((dish) => (
        
                    <li  onClick={()=>overFlow(dish)} key={dish.title} className={`p-8 flex justify-between gap-x-6 py-3 ${ !dish.in_stock ? "cursor-not-allowed opacity-50" : ""}` }>
                      <div className="flex min-w-0 gap-x-4 relative align-items-center">
                        {dish.icon === "link" 
                        ? 
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12 text-red-400">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                          </svg>
              

                        :
                          <img src={dish.icon} alt={dish.title} className='w-12 h-12 object-cover bg-gray-100' style={{borderRadius:"100%", filter:`${dish.in_stock?'':"saturate(0)"}`}} data-bs-toggle="modal" data-bs-target="#staticBackdrop"/>
                        }
                        <div className="min-w-0 flex-auto ">
                          <p className={"text-sm font-semibold leading-6 text-green-800 capitalize"} >{dish.title}</p>
                          <p className="mt-1 truncate text-xs leading-5 text-gray-500">{dish.in_stock ? dish.description : dish.description = "Désolé, plat indisponible. Revenez plus tard." }</p>
                        </div>
                      </div>
                      <div className="dropdown shrink-0 sm:flex sm:flex-col sm:items-end items-center text-center">
                      <p className="text-sm leading-6  font-semibold" style={{color:"#B9922E"}}>{dish.price}</p>
                      <button id="openModal" type="button" className="text-gray-500" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                        { dish.description !== "" 
                        ?
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                          </svg>
                        :
                          ""
                        } 
                      </button> 
                


                
                      </div>
              
                    </li>
      

                    )) 
                  }
                  </ul>
        
                </main>
              }
              )
              }

              <div className="modal fade overflow-hidden " id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                          <div className="modal-dialog m-auto rounded p-4 ">
                            <div className="modal-content overflow-scroll">
                              <div className="flex justify-content-end p-3">
                                
                                <button type="button" id="clodeModalbtn" data-bs-dismiss="modal" aria-label="Close"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                  </svg>
                                </button>
                              </div>
                              <div className="modal-body ">
                                <div className="crrrrr overflow-y-scroll">

                                  <div className='mb-5 rounded-2xl overflow-hidden'>
                                  {handleToChange.gallery_total
                                    ? 
                                    <GalleryCarousel photos={photos} icon={handleToChange.icon}                                  />
                                    :
                                    <img src={handleToChange.icon} class="d-block w-100" alt="..." />

                                  }
                                  </div>












                                    <div className="container mx-auto">
                                        <h1 className="text-3xl font-bold text-main capitalize">{handleToChange.title}</h1>
                                        <h3 className='text-2xl font-semibold' style={{color:"#B9922E"}}>{handleToChange.price} DH</h3>
                                        <p className="mt-4 text-lg text-gray-600">{handleToChange.description}</p>
                                    </div>


                                </div>
                              </div>
                              
                            </div>
                          </div>  
              </div>

            </div>
          </div>
        </>
    )
}

export default Menu;



export const MenuHeader = (props) =>{
  
  return <>
  <div className="p-3 flex items-center justify-between" id="header-menu">
  <Link to='/' >
  <button className="animated-side-right bg-gray-100 p-2 rounded-full bg-gray-00 text-green-800 hover:bg-gray-00 focus:outline-none focus:bg-gray-300 font-bold">
 
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
  <path fill-rule="evenodd" d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z" clip-rule="evenodd" />
</svg>

    
  </button>
  </Link>
  <h1 className="text uppercase bg-gray-100 truncate leading-5 font-semibold animated-side-down" style={{color:'#B9922E'}}>{props.titre}</h1>
  <button type="button" data-bs-toggle="offcanvas" data-bs-target="#demo"
        className="bg-gray-100 px-2 py-2 text-green-800 font-semibold animated-side-left"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />
        </svg>
  </button>
</div>  





    <Offcanvas/>
  </>

}



const CategoryNavbar = ({uniqueCategories}) =>{
  return <>
  <div>
          
          {uniqueCategories.length > 1 ? 
            <div className="flex px-4 m-4 gap-8 pb-4 overflow-x-scroll hiddendegrade " >
              {uniqueCategories.map((item)=>{return (
                <>
                  <a className="bg-white text-nowrap p-2 px-4 rounded  shadow-sm" href={`#${item}`}>{item}</a>
                </>)})}
            </div>
            :
      
            ""
          }
    
        </div> 
  </>
}



const GalleryCarousel  = ({icon,photos})=>{
  return (
    <>
    <div id="carouselExampleIndicators" class="carousel slide ">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    {photos.map((item,index)=>{
      return (<>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index + 1} aria-label={`Slide ${index + 1}`}>{index}</button>
      </>)
    })}
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={icon} class="d-block w-100" alt="..." />
    </div>
    {photos.map((item)=>{
      return (<>
      <div class="carousel-item">
      <img src={item.photo_url} class="d-block w-100" alt="..." />
    </div>

      </>)
    })}
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
    </>
  )
}