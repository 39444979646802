import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Contact() {
    const [error,setError] = useState(null)
    const [success,setSuccess] = useState(null)
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setError(null)
        setSuccess(null)
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const EmailValid = ()=>{
            const value = formData.email
            // Basic email validation regex pattern
            const isValidEmail = /\S+@\S+\.\S+/.test(value);
            // Update the form state only if the email is valid or empty
            return isValidEmail;
        }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!EmailValid()){
            setError('Vous devez saisir une adresse e-mail valide')
        }
        
        try {
            const response = await fetch('https://laruchedujardin.ma/api/recommandation.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setSuccess('Recommandation envoyée avec succès. Merci de nous aider à savoir comment vous aider.!');
                // Optionally, reset form fields here
            } else {
                setError("Échec de l'envoi du message");
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    
    return (
        <div className='lg:p-20 md:p-10 '>
            <div className="max-w-xl m-auto rounded-2xl shadow-xl bg-white p-8 min-h-screen" >

            <Link to='/' className='max-w-24 col-3'>
            <button className="animated-side-right p-2 rounded-full bg-gray-00 text-main hover:bg-gray-00 focus:outline-none focus:bg-gray-300 font-semibold flex gap-2 ">
            
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
            <path fill-rule="evenodd" d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z" clip-rule="evenodd" />
            </svg>
            Retour

                
            </button>
            </Link>
            
            <form onSubmit={handleSubmit} className='flex w-full flex-col'>
            
            
            <img src='https://laruchedujardin.ma/src/logo.png' className='w-20 m-auto my-4 ' alt='la ruche du jardin'/>
            <h2 className="title-font mb-3 text-2xl text-center font-medium text-main ">Recommendations</h2>
            
            
           
            {!success
            ?
            <>
            <p className="mb-3; leading-relaxed text-gray-600 text-center">Si vous avez rencontré des problèmes ou si vous avez apprécié notre produit, n'hésitez pas à partager avec nous !
            </p>
            <div className="mb-4 mt-4">
                <label for="email" className="text-sm leading-7 text-gray-600 font-semibold ">Adresse email</label>
                <input 
                type="text" 
                autoComplete='email'
                onChange={handleChange} 
                
                id="email" name="email" className="w-full rounded border border-gray-300 bg-white py-1 px-3 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-green-500 focus:ring-2 focus:ring-green-700" placeholder='Votre adresse email' />
            </div>
            <div className="">
                <label for="message" className="text-sm leading-7 text-gray-600 font-semibold ">Votre commentaire</label>
                <textarea 
                onChange={handleChange}                 
                id="message" name="message" 
                placeholder='Dites-nous comment nous pouvons vous aider.'
                className="h-32 w-full resize-none rounded border border-gray-300 bg-white py-1 px-3 text-base leading-6 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-green-500 focus:ring-2 focus:ring-green-700"></textarea>
            </div> 
            <div className='alert'>
            
            {error 
            ? <p className='text-center justify-center text-sm text-red-400 flex gap-1'>
                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                    </svg>

                {error}</p>
            :''}
            </div>
        
                <button type='submit' className="rounded-3xl focus:bg-green-900 border-0 bg-main py-2 px-6 text-lg text-white hover:bg-green-700 focus:outline-none font-semibold">Envoyer</button>
            
            </>
            :
            <>
            <div className='alert'>
                <p className='text-center justify-center text-lg text-gray-600 '>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-20 h-20 text-main m-auto text-center my-5">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>



                {success}
                </p>
            </div>
            <Link to="/">
            <button type='button' className="m-auto flex align-items-center justify-center gap-2 border bg-gray-100 rounded-3xl py-2 px-6 text-lg text-main hover:text-white hover:bg-gray-200 focus:outline-none font-semibold">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                    </svg>
                    Retourner au menu
            </button>
            </Link>
            </>
            }
            
            </form> 
            

            
            
        </div>
        </div>
    );
}

export default Contact;

