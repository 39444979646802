import React, { useState, useEffect , createContext } from 'react';
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';



import Menu from "./menu.js";
import ClassePage from "./classe.js";
import Contact from './contact.js';

export const AppContext = createContext();



function App() {
  const [classesData, setclassesData] = useState([]);
  const [settings, setsettings] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
  
      try {
        const responseClasses = await fetch('https://laruchedujardin.ma/api/classes.php');
        const classesData = await responseClasses.json();
        setclassesData(classesData);
      } catch (error) {
        console.error('Error fetching classesData:', error);
      }
      try {
        const response = await fetch('https://laruchedujardin.ma/api/aboutapp.php');
        const settingsList = await response.json();
        setsettings(settingsList);
      } catch (error) {
        console.error('Error fetching classesData:', error);
      }
    };
  
    fetchData();

  }, []);
  
  return (
    <>
        <AppContext.Provider value={{settings}}>
          <Router>
            <Routes>
                <Route path="/" element={<ClassePage data={classesData} versions={settings}/>} />
                <Route path="/restaurant/menu/:classe" element={<Menu />} />
                <Route path="/restaurant/recommendation" element={<Contact/>} />
                <Route path="*" element={<ClassePage data={classesData} />} />
            </Routes>
          </Router>
        </AppContext.Provider>

    </>
  );
}

export default App;

