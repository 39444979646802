export const ClassLoader = ()=>{
    return (<>
    <li className="max-w-xs m-auto w-3/4 animate-pulse " >
                  <div className="items-center text-center py-10 gap-y-6 bg-gray-200">
                    <img className="object-cover h-36 w-36 m-auto rounded-full bg-gray-300"  alt='la ruche du jardin' />
                    <div>
                      <h3 className="mt-3 bg-gray-400 py-1 m-2 w-2/3 m-auto"> </h3>
                      <h3 className="mt-3 bg-gray-300 py-1 m-2 w-1/2 m-auto"> </h3>
                      <p className="text-sm leading-7 text-gray-500"> </p>
                    </div>
                  </div>
    </li>
    <li className="max-w-xs m-auto w-3/4 animate-pulse " >
                  <div className="items-center text-center py-10 gap-y-6 bg-gray-200">
                    <img className="object-cover h-36 w-36 m-auto rounded-full bg-gray-300" alt='la ruche du jardin' />
                    <div>
                      <h3 className="mt-3 bg-gray-400 py-1 m-2 w-2/3 m-auto"> </h3>
                      <h3 className="mt-3 bg-gray-300 py-1 m-2 w-1/2 m-auto"> </h3>
                      <p className="text-sm leading-7 text-gray-500"></p>
                    </div>
                  </div>
    </li>
    
    </>)
}
export const MenuLoader = ()=>{
    return (<>
    
<div className="m-auto">
<div className="flex px-4 m-4 gap-8 pb-4 overflow-x-scroll hiddendegrade animate-pulse " >
<span className="bg-gray-200 dark:bg-gray-700 text-nowrap py-3 px-16 rounded w-3/4 "> </span>
<span className="bg-gray-200 dark:bg-gray-700 text-nowrap py-3 px-8 rounded w-3/4 "> </span>
<span className="bg-gray-200 dark:bg-gray-700 text-nowrap py-3 px-8 rounded w-3/4 "> </span>
</div>
<div role="status" class="w-full p-4 space-y-4 divide-y divide-gray-200 rounded animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
    <div class="flex items-center justify-between">
        <div className="flex gap-1 align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 text-gray-100 bg-gray-200 p-2 rounded-full dark:text-gray-700 ">
            </svg>

            <div className="flex-1">
            <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
        </div>
        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
    </div>
    <div class="flex items-center justify-between">
        <div className="flex gap-1 align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 text-gray-100 bg-gray-200 p-2 rounded-full dark:text-gray-700 ">
            </svg>

            <div className="flex-1">
            <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
        </div>
        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
    </div>
    <div class="flex items-center justify-between">
        <div className="flex gap-1 align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 text-gray-100 bg-gray-200 p-2 rounded-full dark:text-gray-700 ">
            </svg>

            <div className="flex-1">
            <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
        </div>
        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
    </div>
    
</div>
</div>

    </>)
}